import React from 'react'
const CartColumns = () => {
  return (
    
<div className="grid grid-cols-[450px_1fr_1fr_1fr_50px] text-center font-semibold">
    <div >Item</div>
    <div >Price</div>
    <div >Quantity</div>
    <div >Subtotal</div>
</div>
  )
}


export default CartColumns
