import React from "react";
import { useFilterContext } from "../context/filter_context";
import GridView from "./GridView";
import ListView from "./ListView";
import { useProductsContext } from "../context/products_context";
import Loading from "./Loading";
import Error from "./Error";
const ProductList = () => {
  const { filtered_products: products, grid_view } = useFilterContext();
  const {products_loading: loading, products_error: error} = useProductsContext()

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (products.length < 1) {
    return (
      <>
        <h5 style={{ textTransform: "none" }}>Products not found.</h5>
      </>
    );
  }else{   
    if (products && grid_view) {
      return <GridView products={products} />
  }else{
      return <ListView products={products} />
  }}};

export default ProductList;
